.row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

@media (max-width: 767px) {
  .row.fill-container {
    display: block !important;
  }
}

.box_icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
}

.description_title {
  text-decoration: underline;
}

.bg-deep-dark {
  background-color: #151617 !important;
}

.bg-deep-dark-hover {
  background-color: #151617 !important;
}

.bg-deep-dark-hover:hover {
  background-color: #707477 !important;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fill-container {
  flex: 1 !important;
}