@media (max-width: 992px) {
  .auth-buttons {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-auth-buttons {
    display: none;
  }
}

@media (max-width: 768px) {
  .responsive-logo {
    width: 220px;
  }
}

@media (min-width: 768px) {
  .responsive-logo {
    width: auto;
  }
}

.text-navbar {
  color: rgba(255,255,255,.5);
}
